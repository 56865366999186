import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueTypedJs from 'vue-typed-js'
import VueResize from 'vue-resize'
import VueParticles from 'vue-particles'

// Initialize the Fingerprint agent on application start.
const fpPromise = FingerprintJS.load({
  apiKey: 'X7hbkyCA4p0qUNwAsPwM',
  endpoint: [
    'https://metrics.danpavlov.com',
    FingerprintJS.defaultEndpoint
  ],
  scriptUrlPattern: [
    'https://metrics.danpavlov.com/web/v3/X7hbkyCA4p0qUNwAsPwM/loader_v3.11.8.js',
    FingerprintJS.defaultScriptUrlPattern
  ]
})

// Get the Fingerprint visitorId
fpPromise
  .then(fp => fp.get({ extendedResult: true }))

Vue.config.productionTip = false

Vue.use(VueTypedJs)

Vue.use(VueResize)

Vue.use(VueParticles)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
